// ------------------------------------------------------------ BACKGROUND IMAGES
body {
  position: relative;
  background: #F8F8F8;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    transition: background-image 0.2s ease-in-out, opacity 0.2s ease;
  }

  &.background_none {
    &::before {
      opacity: 0;
    }

    .ProcessScriptModal_stepHeader::before,
    .ProcessStepHeading::before {
      background: #F8F8F8;
    }
  }

  &.background_greenery {
    &::before {
      background: no-repeat center/cover url("../../assets/images/backgrounds/BackgroundGreenery.jpg");
      opacity: 0.25;
      filter: blur(1px);
    }

    .ProcessScriptModal_stepHeader::before,
    .ProcessStepHeading::before {
      background: no-repeat center/cover url("../../assets/images/backgrounds/BackgroundGreenery.jpg");
      box-shadow: inset 0 0 0 1000px rgba(248, 248, 248, 0.76);
      filter: blur(1px);
    }
  }

  &.background_mountains {
    &::before {
      background: no-repeat center/cover url("../../assets/images/backgrounds/BackgroundMountains.jpg");
      opacity: 0.4;
      filter: blur(1px);
    }

    .ProcessScriptModal_stepHeader::before,
    .ProcessStepHeading::before {
      background: no-repeat center/cover url("../../assets/images/backgrounds/BackgroundMountains.jpg");
      box-shadow: inset 0 0 0 1000px rgba(248, 248, 248, 0.6);
      filter: blur(1px);
    }
  }
  
  &.background_gradient {
    &::before {
      background: no-repeat center/cover url("../../assets/images/backgrounds/BackgroundGradient.png");
      opacity: 0.25;
      filter: blur(1px);
    }

    .ProcessScriptModal_stepHeader::before,
    .ProcessStepHeading::before {
      background: no-repeat center/cover url("../../assets/images/backgrounds/BackgroundGradient.png");
      box-shadow: inset 0 0 0 1000px rgba(248, 248, 248, 0.77);
      filter: blur(1px);
    }
  }

  &.background_sky {
    &::before {
      background: no-repeat center/cover url("../../assets/images/backgrounds/BackgroundSky.jpg");
      opacity: 0.25;
      filter: blur(1px);
    }

    .ProcessScriptModal_stepHeader::before,
    .ProcessStepHeading::before {
      background: no-repeat center/cover url("../../assets/images/backgrounds/BackgroundSky.jpg");
      box-shadow: inset 0 0 0 1000px rgba(248, 248, 248, 0.77);
      filter: blur(1px);
    }
  }
  
  // ------------------------------------------------------------ NO BACKGROUND HELPER
  &.background_white {
    background: white;

    &:before {
      display: none;
    }
  }
}
