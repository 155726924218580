// ------------------------------------------------------------ FONT FACE DECLARATIONS

// MULISH

@font-face {
  font-family: "Mulish Light";
  src: url("../fonts/Mulish-VariableFont_wght.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Mulish Regular";
  src: local("Mulish-Regular"),
    url("../fonts/Mulish-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mulish Italic";
  src: local("Mulish-Italic"),
    url("../fonts/Mulish-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mulish Medium";
  src: local("Mulish-Medium"),
    url("../fonts/Mulish-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mulish SemiBold";
  src: local("Mulish-SemiBold"),
    url("../fonts/Mulish-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mulish Bold";
  src: local("Mulish-Bold"),
    url("../fonts/Mulish-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mulish ExtraBold";
  src: local("Mulish-ExtraBold"),
    url("../fonts/Mulish-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mulish Bold Italic";
  src: url("../fonts/Mulish-Italic-VariableFont_wght.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

// ANONYMOUS PRO

@font-face {
  font-family: "Anonymous Pro Bold";
  src: local("AnonymousPro-Bold"),
    url("../fonts/AnonymousPro-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

// ------------------------------------------------------------ ELEMENT DEFAULTS

// HEADINGS

h1 {
  font-family: "Anonymous Pro Bold";
  font-size: 1.625rem; // 26px
  font-weight: normal;
}

h2 {
  font-family: "Mulish Bold";
  font-size: 2.25rem; // 36px
  font-weight: normal;
}

h3 {
  font-family: "Mulish Bold";
  font-size: 1.4375rem; // 23px
  font-weight: normal;
}

h4 {
  font-family: "Mulish Bold";
  font-size: 1.125rem; // 18px
  font-weight: normal;
}

h5 {
  font-family: "Mulish Bold";
  font-weight: normal;
}

// BODY

p {
  font-family: "Mulish Regular";
  font-size: 1rem; // 16px
}

// OTHER

input, textarea, select {
  font-family: "Mulish Medium";
  font-size: 0.75rem; // 12px
}

label {
  font-family: "Mulish Bold";
  font-size: 0.875rem; // 14px
}

a, .link {
  font-family: "Mulish Regular";
  font-size: 0.625rem; // 10px
  color: black;

  // &:hover {
  //   font-family: "Mulish SemiBold";
  // }
}

li {
  font-family: "Mulish Medium";
  font-size: 0.6875rem; // 11px
}

// TABLES

th {
  font-family: "Mulish Bold";
  // font-size: 0.875rem; // 14px
  font-size: 1rem; // 16px
  font-weight: normal;
}

td {
  font-family: "Mulish Medium";
  // font-size: 0.875rem; // 14px
  font-size: 1rem; // 16px
}

// ------------------------------------------------------------ BUTTONS

.Button_primary,
.Button_secondary,
.Button_addParcel {
  font-family: "Mulish Bold";
  font-size: 0.875rem; // 14px
}

.Button_inline {
  font-family: "Mulish Bold";
  font-size: 0.75rem; // 12px
}

.Button_label {
  font-size: 0.75rem; // 12px
}

// ------------------------------------------------------------ ALERTS

.Alert {
  p, a {
    font-family: "Mulish SemiBold";
    font-size: 0.8125rem; // 13px
    line-height: 164%;
  }
}

// ------------------------------------------------------------ TYPOGRAPHY HELPERS

// NEW PARAGRAPH SIZES
.pSmall {
  font-size: 0.75rem; // 12px
}
.pDefault {
  font-size: 0.875rem; // 14px
}
.pLarge {
  font-size: 1rem; // 16px
}
.pSubheading {
  font-size: 1.125rem; // 18px
}

// NEW HEADING SIZES
.heading1 {
  font-size: 2.25; // 36px
}
.heading2 {
  font-size: 1.875rem; // 30px
}
.heading3 {
  font-size: 1.5625rem; // 25px
}
.heading4 {
  font-size: 1.3125rem; // 21px
}
.heading5 {
  font-size: 1.0625rem; // 17px
}
.heading6 {
  font-size: 0.875rem; // 14px
}

// WEIGHTS
.light {
  font-family: "Mulish Light";
}
.regular {
  font-family: "Mulish Regular";
}
.italic {
  font-family: "Mulish Italic";
}
.medium {
  font-family: "Mulish Medium";
}
.semibold {
  font-family: "Mulish SemiBold";
}
.bold {
  font-family: "Mulish Bold";
  font-weight: normal;
  &:hover {
    font-family: "Mulish Bold";
  }
}
.extrabold {
  font-family: "Mulish ExtraBold";
  font-weight: normal;
}
.bold-italic {
  font-family: "Mulish Bold Italic", "Mulish Bold";
  font-weight: normal;
  font-style: italic;
}

// ALIGNMENT
.align_left {
  text-align: left;
}
.align_center {
  text-align: center;
}
.align_right {
  text-align: right;
}

// STRIKETHROUGH
.strikethrough {
  text-decoration: line-through;
  color: #C72025; // variable colours.alert
  * {
    color: #C72025; // variable colours.alert
  }
}

// CODE BLOCKS
code {
  color: #CD375C;
  background-color: #F6F6F6;
  border: 1px solid #DADADA;
  border-radius: 4px;
  padding: 4px 8px;
  line-height: 1.4;
  word-wrap: break-word;

  &.block {
    display: block;
    margin: 10px 0;
  }
}