// ------------------------------------------------------------ DEFAULT VARIABLES
$duration-standard: 0.2s;
$duration-slow: 0.3s;
$easing: cubic-bezier(0.65, 0, 0.35, 1);

// ------------------------------------------------------------ GENERIC FADE TRANSITION
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity $duration-standard ease;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity $duration-standard ease;
}

// ------------------------------------------------------------ ORDER CATEGORY TRANSITION
$oc-translate-distance: 0;
.orders-category-enter {
  opacity: 0;
  transform: translateX(-$oc-translate-distance);
}
.orders-category-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity $duration-slow ease, transform $duration-slow ease;
}
.orders-category-exit {
  opacity: 1;
}
.orders-category-exit-active {
  opacity: 0;
  transform: translateX($oc-translate-distance);
  transition: opacity $duration-slow ease, transform $duration-slow ease;
}

// ------------------------------------------------------------ MODAL TRANSITION
$modal-translate-distance: 20px;
.modal-enter {
  opacity: 0;

  .Modal {
    opacity: 0;
    transform: translateY($modal-translate-distance);
  }
}
.modal-enter-active {
  opacity: 1;
  transition: opacity $duration-standard ease;

  .Modal {
    opacity: 1;
    transform: translateY(0);
    transition: opacity $duration-slow 0.1s ease, transform $duration-slow 0.1s ease;
  }
}
.modal-exit {
  opacity: 1;
}
.modal-exit-active {
  opacity: 0;
  transition: opacity $duration-standard ease;
}

// ------------------------------------------------------------ SPLASH SCREEN TRANSITION
$splash-translate-distance: 20px;
.splash-appear {
  opacity: 1;

  .Splash {
    opacity: 0;
  }
}
.splash-appear-active {
  opacity: 1;
  transition: opacity $duration-slow ease;

  .Splash {
    opacity: 1;
    transition: opacity 0.4s ease 0.2s;
  }
}
// .splash-enter {
//   opacity: 1;

//   .Splash {
//     opacity: 0;
//   }
// }
// .splash-enter-active {
//   opacity: 1;
//   transition: opacity $duration-slow ease;

//   .Splash {
//     opacity: 1;
//     transition: opacity 0.6s ease;
//   }
// }
.splash-exit {
  opacity: 1;
}
.splash-exit-active {
  opacity: 0;
  transition: opacity $duration-slow ease;
}

// ------------------------------------------------------------ ROTATE ANIMATION

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  
  to {
    transform: rotate(360deg);
  }
}

// ----------------------------------------------------------- ELEMENT SHOW/HIDE

.elementTransition {
  transition:
    opacity $duration-slow $easing,
    transform $duration-slow $easing;
  
  &.hidden {
    opacity: 0;
    pointer-events: none;
    transform: translateX(-30px);
  }
}