// ------------------------------------------------------------ TEXT OVERFLOW
.ellipsis {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ellipsisMultiline {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

// ------------------------------------------------------------ BUTTONS
.button {
  cursor: pointer;
  transition: transform 0.2s ease; // ${transitions.default}
  user-select: none;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.975);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      transform: scale(1);
    }

    &:active {
      transform: scale(1);
    }
  }
}

.buttonShadow {
  cursor: pointer;
  transition: box-shadow 0.2s ease, transform 0.2s ease; // ${transitions.default}
  user-select: none;

  &:hover {
    box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.25);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  }
}

.top_right {
  position: absolute;
  top: 20px;
  right: 20px;
}

// ------------------------------------------------------------ DIVS
.divider {
  border-bottom: 1px solid #dadada;
}

// ------------------------------------------------------------ COLUMNS

.columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 11px;
  grid-gap: 11px;

  .SelectInput {
    display: grid;
    
    label {
      margin-top: 20px;
    }
  }
}

// ------------------------------------------------------------ TABLES
.summaryTable {
  border-spacing: 0 1px !important;

  &:not(.noNegativeMargin) {
    margin-bottom: -1px !important;
  }

  th {
    padding-bottom: 10px;
    border-bottom: 3px solid black;
  }

  tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  td,
  th {
    padding: 10px 7px;
  }

  .quantity {
    font-family: "Mulish ExtraBold";
  }
}

.noRowColours {
  background-color: transparent !important;

  tr {
    background-color: transparent !important;
  }
}

// ------------------------------------------------------------ PRINTABLE
.printable_hidden {
  position: absolute;
  pointer-events: none;
  display: none;
}

.printable_visible {
  > * {
    display: block !important;
    pointer-events: all;
    transform: scale(1);
  }

  .printable_hidden {
    display: block !important;
    pointer-events: all;
    transform: scale(1);
    position: relative;
    break-after: page;
  }
}

.printable_preview {
  position: fixed;
  z-index: 99999;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  background: white;
  display: block;
  pointer-events: all;
}

.printable_preview_small {
  position: fixed;
  z-index: 99999;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%) scale(0.85);
  background: white;
  display: block;
  pointer-events: all;

  .Close {
    transform: scale(1.18);
  }
}

.printable_dev {
  position: fixed;
  z-index: 99999;
  top: 100px;
  left: 10px;
  background: white;
  display: block;
  box-shadow: 0 0 5px 5px blue;
  pointer-events: all;
}

.printable_dev > div {
  border: 1px solid red;
}

.printable_scroll {
  max-height: 98vh;
  overflow-y: scroll;
}

.printable_transparent {
  opacity: 0.5;
  pointer-events: none;
}

// ------------------------------------------------------------ DASHBOARD COUNT ANIMATION HELPERS
$numCircles: 12;
$degrees: calc(360deg / $numCircles);
@for $i from 1 through $numCircles {
  .DashboardCount_animationCircle:nth-child(#{$i}) {
    transform: translate(-50%, -50%) rotate($degrees * $i) translateX(30px);
  }
}

// ------------------------------------------------------------ FAKE DISABLED BUTTON HELPER

.fakeDisabledButton {
  opacity: 0.5;

  * {
    cursor: default !important;
  }
}

// ------------------------------------------------------------ LOCAL BOOTSTRAP

.local-bootstrap {
  @import "~bootstrap/scss/bootstrap";

  &.typeahead {
    font-family: 'Mulish';

    input {
      width: 100%;
      height: 48px;
      margin: 8px 0;
      padding: 13px;
      border-radius: 4px;
      border: 1px solid #9B9B9B;
  
      &:active, &:focus {
        border: 2px solid black !important;
        outline: none;
        box-shadow: none;
        padding: 12px;
      }
  
      &::placeholder {
        color: #D4D4D4;
      }
    }

    .dropdown-menu {
      background-color: white;
      box-shadow: 0 0 5px 0 rgba(159, 159, 159, 0.25);

      .dropdown-item {
        font-size: 0.875rem; // 14px

        &:hover {
          background-color: #e8e8e8;
          color: black;
        }
        &.active {
          background-color: #25A8C5;
          color: white;
        }

        mark {
          padding: 0;
          font-family: "Mulish Bold"
        }
      }
    }
  }
}

