// ------------------------------------------------------------ STYLE MODULE IMPORTS

@import './typography';
@import './helpers';
@import './transitions';
@import './backgrounds';

// ------------------------------------------------------------ GENERAL STYLES

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

main {
  margin: 0;
  padding: 0;
}

article {
  width: 100%;
  max-width: 578px;
  margin: 0 auto;
}

// ------------------------------------------------------------ HIDE RECAPTCHA BADGE

.grecaptcha-badge {
  visibility: hidden;
}